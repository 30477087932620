<template>
    <div class="form-container">
        <div class="mb-5 factura-container position-relative">
            <!-- <ButtonsInput
                titulo="¿Está tu auto pagado?"
                :options="[{value: 'si', label: 'Sí'},{value: 'no', label: 'No'}]"
                :errorFlag="errors?.financiado"
                :value="financiado === 0 ? '0' : financiado"
                :updateValue="setFinanciado"
                /> -->
                <InputKilometraje
                :forceLabel=true
                :errorFlag="errors?.kms"
                >
                </InputKilometraje>
            <!-- Botón para regrear de pantalla -->
            <div class="position-absolute arrow-container">
                <button class="btn" @click="onBackClick">
                    <img src="@/assets/img/arrowLeft.svg" />
                </button>
            </div>
        </div>

        <!-- Stepper con bolitas -->
        <div class="mb-4">
            <StepperBolitas :length="length" :step="step" :onClick="onClickStepper"/>
        </div>

        <!-- Botón para solicitar préstamo, solo en caso de que no permita actualizar CP -->
        <div class="text-center mb-5" v-if="step == length">
            <ButtonConfirm text="Solicitar préstamo" :icon="true" :clickButton="onClickSolicitarPrestamo"/>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import store from "@/store";

    // import ButtonsInput from '@/components/FormularioMexico/Inputs/ButtonsInput.vue';
    import StepperBolitas from "@/components/FormularioMexico/Inputs/StepperBolitas.vue";
    import ButtonConfirm from '@/components/FormularioMexico/Inputs/ButtonConfirm.vue';
    import InputKilometraje from "../../Inputs/InputKilometraje.vue";
    

    export default {
        name: 'Form2819Step2',
        props: {
            errors: Object,
            updateStep: Function,
            onBackClick: Function,
            onClickStepper: Function,
            step: Number,
            length: Number,
            onClickSolicitarPrestamo: Function,
            validateEvent: Function,
        },
        store,
        computed: mapState({
            financiado: (state) => state?.formulario?.financiacion
        }),
        components: {
            // ButtonsInput,
            StepperBolitas,
            ButtonConfirm,
            InputKilometraje
        },
        methods: {
            setFinanciado: function(value){
                store.commit('setFinanciacion', value)
                if(this.length > this.step){
                    // Al detectar el cambio se actualiza el paso, siempre y cuando se permita agregar el CP
                    this.updateStep(3)
                }
            },
        },
        mounted: function(){
            // Se valida y agrega el evento pregunta2_api_2819
            window.dataLayer = window.dataLayer || [];
            if(!this.validateEvent('pregunta2_api_2819')){
                window.dataLayer.push({
                    event: 'pregunta2_api_2819',
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "@/assets/styles/variables.scss";
    @import "bootstrap/scss/_functions.scss";
    @import "bootstrap/scss/_variables.scss";
    @import "bootstrap/scss/_mixins.scss";

    .arrow-container{
        top: 1rem;
        left: 1rem;
    }

    @include media-breakpoint-up(lg){
        .factura-container{
            padding: 4rem 1rem;
            border: solid 1px $blue-3;
            border-radius: 10px;
            margin-right: auto;
            margin-left: auto;
            max-width: 880px;
            margin-bottom: 1rem;
            margin-top: 2rem;
        }
    }
</style>